import BlockContent from '@sanity/block-content-to-react';
import React from 'react';
import Serializer from './Serializer';

interface Props {
  theme?: string;
  style?: {
    paddingTop: boolean;
    paddingBottom: boolean;
  };
  text: Array<any>;
}

const themeClass = (theme): string => {
  switch (theme) {
    case 'white':
      return 'bg-white text-black';
    case 'green':
      return 'bg-green text-white';
    default:
      return 'bg-black text-white';
  }
};

const RichText = ({
  text,
  theme = 'white',
  style = {
    paddingBottom: false,
    paddingTop: false,
  },
}: Props): JSX.Element => {
  if (!text) {
    return null;
  }

  text[0].isFirst = true;
  text[text.length - 1].isLast = true;

  return (
    <div
      className={`${themeClass(
        theme
      )} relative gap-10 md:gap-20 flex justify-center items-center flex-col py-8 ${
        style?.paddingBottom ? 'pb-[5rem] md:pb-[10rem]' : ''
      } ${style?.paddingTop ? 'pt-[5rem] md:pt-[10rem]' : ''} px-10`}>
      <div className="max-w-[800px] w-full">
        <BlockContent
          className={'flex flex-col gap-5'}
          blocks={text}
          serializers={Serializer}
          renderContainerOnSingleChild={true}
        />
      </div>
    </div>
  );
};

export default RichText;
