import classnames from 'classnames';
import React from 'react';

import styles from './styles.module.scss';

/**
 * PrimaryButton is just a wrapper that
 * applies the right style to the element.
 *
 * You should pass in whatever element
 * you want to use in the children prop.
 * Eg: button, link..
 *
 * <PrimaryButton>
 *  <button />
 * </PrimaryButton>
 */
interface Props {
  className?: string;
  children: React.ReactNode;
  theme?: 'default' | 'kyber' | 'oxford' | 'transparent';
  inactive?: boolean;
}

function resolveButtonClassname(theme): string {
  switch (theme) {
    case 'default':
      return styles.default;
    case 'kyber':
      return styles.kyber;
    case 'oxford':
      return styles.oxford;
    default:
      return '';
  }
}

function resolveInactiveClassname(inactive): string {
  return inactive ? styles.inactive : '';
}

const PrimaryButton = ({ children, theme, inactive, className }: Props): JSX.Element => {
  return (
    <div
      className={classnames(
        styles.button,
        resolveButtonClassname(theme),
        resolveInactiveClassname(inactive),
        className
      )}>
      {children}
    </div>
  );
};

export default PrimaryButton;
