import React from 'react';

import OfficeListItem from './OfficeListItem';
import styles from './styles.module.scss';

interface Props {
  label: string;
  heading: string;
  lead: string;
  offices: [
    {
      city: string;
      email?: string;
      phone?: string;
      address?: string;
      mapsUrl?: string;
    }
  ];
}

const OfficesBanner = ({ label, heading, offices }: Props): JSX.Element => {
  return (
    <div className={styles.officesBanner}>
      <div className={styles.container}>
        <div className={styles.label}>
          <p>{label}</p>
        </div>
        <div className={styles.content}>
          <div className={styles.heading}>
            <h2>{heading}</h2>
          </div>
          <ul className={styles.officeList}>
            {offices.map((o, i) => (
              <OfficeListItem key={i} {...o} />
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default OfficesBanner;
