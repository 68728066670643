import BlockContent from '@sanity/block-content-to-react';
import classNames from 'classnames';
import React from 'react';

import PrimaryButton from '@/components/buttons/Primary';
import CTALink from '@/components/common/CTALink';
import serializers from '@/components/utils/serializers';

import styles from './styles.module.scss';

interface Props {
  cta: any;
  label: string;
  heading: string;
  lead: string;
  ctaTitle: string;
  ctaInternal?: string;
  ctaExternal?: string;
  backgroundColor?: string;
}

const CTABanner = ({
  label,
  heading,
  lead,
  ctaTitle,
  ctaInternal,
  ctaExternal,
  backgroundColor,
}: Props): JSX.Element => {
  const isOxfordTheme = backgroundColor && backgroundColor === 'oxford';

  return (
    <div className={classNames(styles.ctaBanner, isOxfordTheme && styles.oxfordTheme)}>
      <div className={styles.container}>
        <div className={styles.wrapper}>
          <span className={styles.label}>{label}</span>
          <div className={styles.content}>
            <h2 className={styles.heading}>{heading}</h2>
            <div className={styles.lead}>
              <BlockContent blocks={lead} serializers={serializers} />
            </div>
            <PrimaryButton
              theme={'kyber'}
              className={classNames(styles.button, isOxfordTheme && styles.oxfordButton)}>
              <CTALink linkText={ctaTitle} ctaExternal={ctaExternal} ctaInternal={ctaInternal} />
            </PrimaryButton>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CTABanner;
