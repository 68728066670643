import React from 'react';

import SimpleBlockContent from '@/components/bits/SimpleBlockContent/SimpleBlockContent';

type Props = {
  data: any;
  className?: string;
};

const TextBlock: React.FunctionComponent<Props> = ({ data, className }): JSX.Element => (
  <SimpleBlockContent data={data} className={className} />
);

export default TextBlock;
