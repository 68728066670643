import Image from 'next/image';
import React, { useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import getImageDimensions from '@/components/utils/imageDimensions';
import Icon from '@/components/common/Icon';

interface Props {
  images: any;
}

const ImageGallery = ({ images }: Props): JSX.Element => {
  const [swiper, setSwiper] = useState(null);
  const [activeIndex, setActiveIndex] = useState(0);

  if (images.length === 1) {
    const singleDim = getImageDimensions(images[0]);

    return (
      <div>
        <div>
          <Image alt="" src={images[0].url} width={singleDim.width} height={singleDim.height} />
        </div>
      </div>
    );
  }

  if (images.length < 1) {
    return null;
  }

  return (
    <div className="flex flex-col items-end gap-5  justify-center items-center">
      <Swiper
        className="flex aspect-video h-[calc((100vw-0rem)*9/16)] md:aspect-auto md:h-[600px] w-full"
        lazy={{
          loadPrevNext: true,
          loadPrevNextAmount: 5,
          loadOnTransitionStart: true,
        }}
        onSwiper={setSwiper}
        onSlideChange={() => swiper && setActiveIndex(swiper.activeIndex)}
        preloadImages={true}
        slidesPerView={'auto'}
        centeredSlides={true}
        grabCursor={true}
        spaceBetween={30}>
        {images?.map((image) => {
          return (
            <SwiperSlide key={image._key} className="!w-auto">
              <img alt="" src={image.url} className="h-full w-auto rounded-lg" />
            </SwiperSlide>
          );
        })}
      </Swiper>
      {swiper && (
        <div className="flex gap-4 items-center">
          <button
            className="flex w-14 h-14 bg-black white-white rounded-full justify-center items-center hover:bg-[#333]"
            onClick={() => swiper.slidePrev()}>
            <Icon type="arrowleft" />
          </button>
          <span className="w-12 flex justify-center">{`${activeIndex + 1}/${images.length}`}</span>
          <button
            className="flex w-14 h-14 bg-black white-white rounded-full justify-center items-center hover:bg-[#333]"
            onClick={() => swiper.slideNext()}>
            <Icon type="arrowright" />
          </button>
        </div>
      )}
    </div>
  );
};

export default ImageGallery;
