interface Props {
  image: {
    asset: {
      _ref: string;
    };
  };
}

export function getImageDimensions({ image }: Props): any {
  const dimensions = image.asset._ref.split('-')[2];

  const [width, height] = dimensions.split('x').map((num: string) => parseInt(num, 10));
  const aspectRatio = width / height;

  return { width, height, aspectRatio };
}

export default getImageDimensions;
