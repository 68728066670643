import Link from 'next/link';
import React from 'react';

type Props = {
  ctaInternal?: string;
  ctaExternal?: string;
  linkText: string;
};

const CTALink: React.FunctionComponent<Props> = ({
  ctaInternal,
  ctaExternal,
  linkText,
}): JSX.Element => {
  if (ctaInternal) {
    return (
      <Link href={`/${ctaInternal}`} legacyBehavior>
        <a>{linkText}</a>
      </Link>
    );
  }

  return <a href={ctaExternal}>{linkText}</a>;
};

export default CTALink;
