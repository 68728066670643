import Icon from '@/components/common/Icon';
import React, { useEffect, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';

interface Props {
  testimonials: [
    {
      _key: string;
      text: string;
      name: string;
      role: string;
      logo?: any;
    }
  ];
  theme?: string;
  style?: {
    paddingTop?: boolean;
    paddingBottom?: boolean;
  };
}

const themeClass = (theme: string): string => {
  switch (theme) {
    case 'white':
      return 'bg-white text-black';
    case 'green':
      return 'bg-green text-white';
    default:
      return 'bg-black text-white';
  }
};

export const useLoaded = (): boolean => {
  const [loaded, setLoaded] = useState(false);
  useEffect(() => setLoaded(true), []);
  return loaded;
};

const TestimonalsBanner = ({
  testimonials,
  theme = 'black',
  style = {
    paddingBottom: true,
    paddingTop: true,
  },
}: Props): JSX.Element => {
  const [swiper, setSwiper] = useState(null);
  const [isBeginning, setIsBeginning] = useState(null);
  const [isEnd, setIsEnd] = useState(null);

  const onChange = (s: any): void => {
    setSwiper(s);
    setIsBeginning(s.isBeginning);
    setIsEnd(s.isEnd);
  };

  const loaded = useLoaded();

  if (!loaded) return null;

  return (
    <div
      className={`${themeClass(
        theme
      )} relative gap-10 md:gap-20 flex justify-center items-center flex-col py-8 ${
        style?.paddingBottom ? 'pb-[5rem] md:pb-[10rem]' : ''
      } ${style?.paddingTop ? 'pt-[5rem] md:pt-[10rem]' : ''}`}>
      <Swiper
        className="overflow-hidden w-full max-w-[1440px]"
        onSwiper={onChange}
        onResize={onChange}
        onSlideChange={onChange}
        loop={true}
        grabCursor
        breakpoints={{
          0: {
            slidesPerView: 1,
            centeredSlides: true,
          },
          768: {
            slidesPerView: Math.min(2, testimonials.length),
            centeredSlides: false,
          },
          1280: {
            slidesPerView: Math.min(3, testimonials.length),
            centeredSlides: false,
          },
        }}
        speed={640}>
        {testimonials.map((t, i) => (
          <SwiperSlide key={i}>
            <div className="w-full flex justify-center">
              <div className="flex flex-col items-center text-center gap-5 w-full max-w-[800px] px-[2rem]">
                {testimonials[i].logo?.asset.url && (
                  <p>
                    <img
                      className="w-[9rem] h-[4rem] object-contain"
                      src={testimonials[i].logo.asset.url}
                      alt="logo"
                    />
                  </p>
                )}
                <div className="text-md md:text-h5">{testimonials[i].text}</div>
                <div>
                  {testimonials[i].name && (
                    <p className="text-sm font-bold">{testimonials[i].name}</p>
                  )}
                  {testimonials[i].role && <p className="text-sm">{testimonials[i].role}</p>}
                </div>
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
      {swiper &&
        ((testimonials.length > 1 && swiper.width < 768) ||
          (testimonials.length > 2 && swiper.width < 1280) ||
          (testimonials.length > 3 && swiper.width >= 1280)) && (
          <div className="flex gap-5 items-center">
            <button
              className={`${
                isBeginning ? 'opacity-50' : ''
              } flex w-14 h-14 border-solid border-[1px] rounded-lg justify-center items-center hover:bg-[#eee] hover:text-black`}
              onClick={() => swiper.slidePrev()}>
              <Icon type="arrowleft" className="w-6" />
            </button>
            {/* <span className="w-12 flex justify-center">{`${activeIndex + 1}/${testimonials.length
            }`}</span> */}
            <button
              className={`${
                isEnd ? 'opacity-50' : ''
              } flex w-14 h-14 border-solid border-[1px] rounded-lg justify-center items-center hover:bg-[#eee] hover:text-black`}
              onClick={() => swiper.slideNext()}>
              <Icon type="arrowright" className="w-6" />
            </button>
          </div>
        )}
    </div>
  );
};

export default TestimonalsBanner;
