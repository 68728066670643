import { useMediaQuery } from '@react-hook/media-query';
import React from 'react';

import styles from './styles.module.scss';

interface Props {
  city: string;
  email?: string;
  phone?: string;
  address?: string;
  mapsUrl?: string;
}

const OfficeListItem = ({ city, email, phone, address, mapsUrl }: Props): JSX.Element => {
  const isPhone = useMediaQuery('(max-width: 768px)');

  const PhoneField = (): JSX.Element => {
    if (isPhone) {
      return <a href={`tel:${phone}`}>{phone}</a>;
    }

    return <span>{phone}</span>;
  };

  return (
    <li className={styles.office}>
      <p className={styles.tCity}>{city}</p>
      <p className={styles.contactInfo}>
        {email && (
          <span>
            <a className={styles.tLink} href={`mailto:${email}`}>
              {email}
            </a>
          </span>
        )}
        {phone && <PhoneField />}
        {address && <span>{address}</span>}
        {mapsUrl && (
          <a className={styles.mapsLink} href={mapsUrl} target="_blank" rel="noreferrer">
            Get directions
          </a>
        )}
      </p>
    </li>
  );
};

export default React.memo(OfficeListItem);
