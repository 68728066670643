import React, { useState } from 'react';
import ImageGallery from '@/components/bits/ImageGallery';
import TextBlock from './TextBlock';
import Icon from '@/components/common/Icon';

type Content = {
  _key: string;
  _type: string;
  [key: string]: any;
};

type Item = {
  _key: string;
  expandableSentence: string;
  content: Content[];
};

const AccordionItem = ({ expandableSentence, content }: Item): JSX.Element => {
  const [open, setOpen] = useState(false);

  return (
    <div className="border-b-[1px] border-solid border-current">
      <button
        className="flex w-full justify-between text-left items-center text-lg py-4 cursor-pointer"
        onClick={() => setOpen(!open)}>
        {expandableSentence}
        <Icon type={open ? 'arrowup' : 'arrowdown'} />
      </button>
      {content?.length > 0 && open && (
        <div className={`flex flex-col gap-5 md:gap-10 py-5 pb-10`}>
          {content.map((c) => {
            switch (c._type) {
              case 'textBlock':
                return <TextBlock className="flex flex-col gap-5" data={c.text} key={c._key} />;
              case 'imageBlock':
                return <ImageGallery key={c._key} images={c.image} />;
              default:
                return null;
            }
          })}
        </div>
      )}
    </div>
  );
};

type Props = {
  theme?: string;
  style?: {
    paddingTop: boolean;
    paddingBottom: boolean;
  };
  items: Item[];
};

const themeClass = (theme: string): string => {
  switch (theme) {
    case 'white':
      return 'bg-white text-black';
    case 'green':
      return 'bg-green text-white';
    default:
      return 'bg-black text-white';
  }
};

const Accordion: React.FunctionComponent<Props> = ({
  theme = 'black',
  style = {
    paddingTop: false,
    paddingBottom: false,
  },
  items = [],
}): JSX.Element => {
  return (
    <div
      className={`${themeClass(
        theme
      )} relative gap-10 md:gap-20 flex justify-center items-center flex-col py-8 ${
        style?.paddingBottom ? 'pb-[5rem] md:pb-[10rem]' : ''
      } ${style?.paddingTop ? 'pt-[5rem] md:pt-[10rem]' : ''} px-10`}>
      <div className="max-w-[800px] w-full">
        {items?.map((item) => (
          <AccordionItem key={item._key} {...item} />
        ))}
      </div>
    </div>
  );
};

export default React.memo(Accordion);
