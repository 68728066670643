import React from 'react';
import { useNextSanityImage } from 'next-sanity-image';
import { sanityClient } from '@/utils/sanity/sanity.server';

type Props = {
  image: any;
  imageFormat: string;
  style: {
    paddingTop: string;
    paddingBottom: string;
  };
  theme?: string;
};

const themeClass = (theme: string): string => {
  switch (theme) {
    case 'white':
      return 'bg-white text-black';
    case 'green':
      return 'bg-green text-white';
    default:
      return 'bg-black text-white';
  }
};

const imageFormatClass = (format: string): string | null => {
  switch (format) {
    case 'square':
      return 'aspect-square';
    case 'video':
      return 'aspect-video';
    default:
      return null;
  }
};

const imageBuilder = (imageUrlBuilder, options): any => {
  const width = options.width || Math.min(options.originalImageDimensions.width, 3000);
  const height = Math.floor((width / 16) * 9);
  return imageUrlBuilder.width(width).height(height).fit('clip');
};

const ImageBanner = ({ image, imageFormat, style, theme }: Props): JSX.Element | null => {
  const imageProps: any = useNextSanityImage(sanityClient, image, {
    imageBuilder,
  });

  if (!image) return null;

  return (
    <div
      className={`${themeClass(theme)} flex justify-center py-8 ${
        style?.paddingBottom ? 'pb-[5rem] md:pb-[10rem]' : ''
      } ${style?.paddingTop ? 'pt-[5rem] md:pt-[10rem]' : ''} px-4 sm:px-12 lg:px-24`}>
      <div className={`max-w-[1440px] flex flex-col gap-10 md:gap-20`}>
        {image && (
          <div className="flex flex-1">
            <img
              alt={image.title || ''}
              className={`${imageFormatClass(imageFormat)} object-cover rounded-lg`}
              src={imageProps?.src}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default ImageBanner;
