import React from 'react';

interface Props {
  figure: any;
  style?: {
    paddingTop: boolean;
    paddingBottom: boolean;
  };
  theme?: string;
}

const themeClass = (theme: string): string => {
  switch (theme) {
    case 'white':
      return 'bg-white text-black';
    case 'green':
      return 'bg-green text-white';
    default:
      return 'bg-black text-white';
  }
};

const FullscreenImage = ({ figure, style, theme }: Props): JSX.Element => {
  return (
    <div
      className={`${themeClass(
        theme
      )} gap-5 md:gap-10 flex justify-center items-center text-center flex-col py-8 ${
        style?.paddingBottom ? 'pb-[5rem] md:pb-[10rem]' : ''
      } ${style?.paddingTop ? 'pt-[8rem] md:pt-[15rem]' : ''} px-4 sm:px-12 lg:px-24`}>
      <figure className="flex w-full relative h-auto max-w-[1440px]">
        <img alt={figure?.alt || ''} src={figure.asset.url} className="w-full" />
        {figure?.caption && <figcaption>{figure?.caption}</figcaption>}
      </figure>
    </div>
  );
};

export default React.memo(FullscreenImage);
